import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./reset.css";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "./router";
import reportWebVitals from "./reportWebVitals";
import Header from "./components/header/index";

import ReactPixel from "react-facebook-pixel";
import GA4React from "ga-4-react";
import TagManager from "react-gtm-module";
ReactPixel.init("809503810598473", {});

function Manager() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-W7MPTTS" });
  }, []);
}

function FacebookPixel() {
  React.useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("1250941808857607");
        ReactPixel.pageView();

        Router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  });
  return null;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <FacebookPixel />
      <Manager />
      <BrowserRouter>
        <Header />
        <Router />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
reportWebVitals();

try {
  setTimeout((_) => {
    const ga4react = new GA4React("G-P0Q04EV2E5");
    ga4react.initialize().catch((err) => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}
