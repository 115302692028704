import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { HeaderContainer } from "./style";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../../img/logo.webp";
import { BsTelephone } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
export default function Header() {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [animation, setAnimation] = useState(false);
  const path = useLocation().pathname;
  useEffect(() => {
    function scrollUp() {
      window.scrollTo(0, 0);
    }
    scrollUp();
  }, [path]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        setAnimation(true);
      } else {
        setAnimation(false);
      }
    });
  }, []);
  return (
    <>
      <HeaderContainer isVisible={menuIsVisible} isAnimation={animation}>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <AiOutlineMenu
          onClick={() => setMenuIsVisible(true)}
          className="menu"
        />
        <AiOutlineClose
          onClick={() => setMenuIsVisible(false)}
          className="menu closeMenu"
        />
        <nav className="linksContainer">
          <ul>
            <Link to="/home" className="link">
              <li
                className={`${path === "/" ? " clicked " : "option"}`}
                onClick={() => {
                  setMenuIsVisible(false);
                }}
              >
                Especialidades
              </li>
            </Link>
            <Link to="/" className="link">
              <li
                className={`${path === "/aaaaaaaa" ? " clicked " : "option"}`}
                onClick={() => {
                  setMenuIsVisible(false);
                }}
              >
                Sobre Nós
              </li>
            </Link>
            <Link to="/" className="link">
              <li
                className={`${path === "/aaaaaaaa" ? " clicked " : "option"}`}
                onClick={() => {
                  setMenuIsVisible(false);
                }}
              >
                Contato
              </li>
            </Link>
            <Link to="/" className="link">
              <li
                className={`${path === "/aaaaaaaa" ? " clicked " : "option"}`}
                onClick={() => {
                  setMenuIsVisible(false);
                }}
              >
                Localização
              </li>
            </Link>{" "}
            <Link to="/" className="link">
              <li
                className={`${path === "/aaaaaaaa" ? " clicked " : "option"}`}
                onClick={() => {
                  setMenuIsVisible(false);
                }}
              >
                Dúvidas
              </li>
            </Link>
            <div className="contactContainer mob">
              <a href="aaaa" target="_blanked">
                <BsTelephone className="iconContact" />
              </a>
              <a href="google" target="_blanked">
                <GoLocation className="iconContact" />
              </a>
            </div>
          </ul>
        </nav>
        <div className="contactContainer">
          <a
            href="https://api.whatsapp.com/send/?phone=5511981644441&text&type=phone_number&app_absent=0"
            target="_blanked"
          >
            <BsTelephone className="iconContact" />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=5511981644441&text&type=phone_number&app_absent=0"
            target="_blanked"
          >
            <GoLocation className="iconContact" />
          </a>
        </div>
      </HeaderContainer>
    </>
  );
}
